<template>
  <v-card id="card" class="card mb-10" elevation="0" @click="$emit('selected-item', item)">
    <v-img :src="item['image-uri']"></v-img>
    <v-card-actions class="flex-column justify-start align-start">
      <div class="card-title">{{ item.name | name }}</div>
      <div class="card-price mt-3">{{ item.price | price }} {{ translate(currency.key, currency.value) }}</div>
    </v-card-actions>
  </v-card>
</template>
<script>
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import { mapGetters } from 'vuex';
export default {
  name: "MenuItem",
  mixins: [itemMixin, translationMixin],
  computed: {
    ...mapGetters({
      currency: 'concept/currency',
    }),
  },
  props: {
    item: {
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.card {
  margin-right: 11.5px;
  margin-left: 11.5px;
  width: 235.23px !important;
  min-height: 342.87px !important;
  background: #ffffff !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.14) !important;
  border-radius: 20px !important;
  &-price {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: var(--v-primary-base);
  }
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    color: #000000;
  }
}
</style>
