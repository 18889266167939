<template>
  <v-footer fixed min-height="211" class="footer" width="100%" :color="$vuetify.theme.themes.light.primary">
    <v-row no-gutters align="center" justify="space-between">
      <v-col class="d-flex">
        <v-badge class="badge" overlap offset-x="30" offset-y="10">
          <template v-slot:badge>
            <v-avatar size="39" color="primary" class="darken-1">
              <span class="content">{{ cartItems }}</span>
            </v-avatar>
          </template>
          <v-icon size="80" color="white" :class="{ 'icon-rtl': locale === 'ar-sa' }">mdi-cart</v-icon>
        </v-badge>
        <div class="mx-12">
          <h3 class="footer-total">{{ translateNumber(total) }} {{ translate('sar_total','SAR TOTAL') }}</h3>
          <p class="footer-description mt-1">{{ translate('total_inclusive','Total is inclusive of VAT') }}</p>
        </div>
      </v-col>
      <v-col :style="{ textAlign: locale === 'ar-sa' ? 'left': 'right' }">
        <solo-button :to="{ name: 'menu.cart' }">{{ translate('checkout','CHECKOUT') }}</solo-button>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import translationMixin from '@/mixins/translations'
import SoloButton from '@/components/base/SoloButton.vue';
import { mapGetters } from "vuex";

export default {
  name: "AppFooter",
  mixins: [translationMixin],
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
    }),
  },
  components:{
    SoloButton
  },
  props:{
    totalItem:{
      required: true,
      default:0
    },
    totalPrice:{
      required: true,
      default: 0,
    }
  }
};
</script>
<style scoped lang="scss">
.footer {
  box-shadow: 0px -23px 46px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
  padding: 0 57px;
  &-description{
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    color: #FFFFFF;
  }
  &-total{
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
    color: #FFFFFF;

  }
  .icon-rtl {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .badge {
    .content {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }
  .btn {
    color: #fff;
    border: 4px solid #fff !important;
    border-radius: 20px !important;
    width: 356px !important;
    height: 126px !important;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 54px;
  }
}
</style>
