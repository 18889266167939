<template>
    <v-card style="box-shadow: none">
      <base-message
          :title="translate('idle_title', 'You’ve been idle')"
          @on-reset="reset"
          v-model="idle">
      </base-message>
        <v-row no-gutters v-if="true" style="height: 100vh">
            <v-col cols="3"><MenuSidebar :items="menus.data" v-model="selectedCategory" @menu-restart="isRestart = true" /></v-col>
            <v-col class="pt-6" :style="{ 'background': $vuetify.theme.themes.light.background }">
                <section class="mx-6 mt-6">
                <div class="menu-carousel">
                  <v-carousel
                      cycle
                      hide-delimiter-background
                      delimiter-icon="mdi-minus"
                      :show-arrows="false"
                      :dark="true"
                  >
                    <v-carousel-item
                        v-for="(item,i) in sliders.included"
                        :key="i"
                        :src="item.attributes['image-uri']"
                    ></v-carousel-item>
                  </v-carousel>
                </div>
                <section class="">
                    <v-window v-model="selectedCategory" style="padding-bottom: 211px">
                        <v-window-item eager v-for="(category, index) in getEnabledCategories" :key="index">
                            <h1 class="category-title my-5">{{ category.attributes.category.name[locale] }}</h1>
                            <section class="category-item d-flex flex-wrap ">
                                <MenuItem v-for="(item, key) in category.attributes.items" :item="item" :key="key" @selected-item="onSelectedItem" />
                            </section>
                        </v-window-item>
                    </v-window>
                </section>
                </section>
            </v-col>
        </v-row>
        <router-view :item="selectedItem" />
        <BaseNotification v-if="isSuccess" v-model="isSuccess" :item="itemName" />
        <AppFooter :total-price="totalPrice" :total-item="totalItem" />
        <base-message
            v-if="isRestart"
            v-model="isRestart"
            :title="translate('restart_order', 'Restart Order')"
            :description="translate('confirm_reset_text','Are you sure you want to reset? Your orders will be removed and you will be taken to the homepage')"
            :is-idle="false"
            @on-reset="reset"
        >
        </base-message>
    </v-card>
</template>
<script>
import translationMixin from '@/mixins/translations'
import MenuSidebar from '@/components/menu/MenuSidebar.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import AppFooter from '@/components/main/AppFooter.vue';
import BaseNotification from '@/components/base/BaseNotification';
import BaseMessage from '@/components/base/BaseMessage';
import { mapGetters, mapMutations } from 'vuex';
export default {
    name:'MenuPage',
    mixins: [translationMixin],
    data: () => ({
        isSuccess: false,
        totalItem: 10,
        isRestart: false,
        totalPrice: '32.99',
        selectedCategory: 0,
        selectedItem: {},
        itemName: '',
        idle: false,
    }),
    components:{
        MenuSidebar,
        MenuItem,
        AppFooter,
        BaseNotification,
        BaseMessage
    },
    computed:{
        ...mapGetters({
            menus: 'cart/getMenu',
            items: 'cart/items',
            sliders: 'app/sliders',
            locale: 'app/getLocale',
        }),
      getEnabledCategories() {
          return this.menus.data.filter((dt) => dt.attributes.category.enabled === 1);
      }
    },
    watch: {
        '$route': {
            immediate: true,
            deep: true,
            handler({query}) {
                if(query.item) {
                    setTimeout(() => {
                        this.itemName = query.item
                        this.isSuccess = true
                        this.$router.replace('/menu')
                    },200)
                }
            }
        }
    },
    mounted() {
        console.log(this.menus)
    },
    onIdle() {
      this.idle = true
    },
    methods:{
        ...mapMutations({
            clear: 'cart/clear'
        }),
        reset() {
            this.$router.push({ name: 'home' })
            this.clear()
            this.idle = false
            console.log('test')
        },
        hasMandatoryModifiers(modGroups) {
          return modGroups.filter((grp) => grp?.min === 1 && grp?.max >= 1)?.length;
        },
        onSelectedItem(item) {
          console.log('item: ', item, this.hasMandatoryModifiers(item['modifier-groups']))
          if (
              !item['must-be-customized'] &&
              !item['is-customizable'] &&
              !item['modifier-groups']?.length
          ) {
            this.itemModal(item);
            return;
          }
          this.customize(item);
        },
        customize(item) {
            this.$router.push({
                name: 'menu.customize',
                params:{
                    catId: this.menus.data[this.selectedCategory].id,
                    itemId: `${this.name(item.name).replace(/\s+/g, '-').toLowerCase()}-${item.id}`
                }
            })
        },
        itemModal(item) {
            this.$router.push({
                name: 'menu.add',
                params:{
                    catId: this.menus.data[this.selectedCategory].id,
                    itemId: `${this.name(item.name).replace(/\s+/g, '-').toLowerCase()}-${item.id}`
                }
            })
        }
    }

}
</script>
<style lang="scss" scoped>
  .v-carousel {
    max-width: 758px;
    max-height: 380px;
    .v-image__image--cover {
      max-height: 380px !important;
      border-radius: 12px !important;
    }

  }


  .category-title{
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 72px;
      letter-spacing: -0.495px;
  }
  .category-item {
    margin-left: -13.5px;
    margin-right: 0px !important;
  }
</style>
