<template>
  <v-snackbar
    class="card secondary--text"
    v-model="model"
    color="primary"
    width="200%"
    min-height="140"
    timeout="3000"
  >
    <section class="d-flex flex-row align-center">
      <v-btn
        height="100"
        class="mx-2 lighten-1"
        width="100"
        color="primary"
        fab
        depressed
      >
        <v-icon size="50" :class="{ 'icon-rtl': locale === 'ar-sa' }">mdi-cart</v-icon>
      </v-btn>
      <p class="card-text" :class="{ 'card-text-ar': locale === 'ar-sa' }">
        <span class="card-title">{{ item }}</span> {{translate('added_to_bag','has been added to bag')}}
      </p>
    </section>
  </v-snackbar>
</template>
<script>
import translationMixin from '@/mixins/translations'
import { mapGetters } from "vuex";
export default {
  name: "BaseNotification",
  mixins: [translationMixin],
  props: {
    item: {
      required: true,
      type: String,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
    }),
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-rtl {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.card {
  left: 0;
  right: 0;
  bottom: 250px;
  margin: auto;

  &-text {
    color: #fff !important;
    font-size: 28px;
    line-height: 121.5%;
    letter-spacing: -0.3px;
    margin-left: 36.86px;
    margin-bottom: 0;
    &-ar {
      margin-left: unset;
      margin-right: 36.86px;
    }
    span {
      font-weight: bold;
    }
  }
}
</style>
